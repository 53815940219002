import { $authApi } from '~/api/global/ApiProvider';
import type { TOffersApiResponse } from '~/features/secret-shop/types/offers/api.types';
import { transform } from '~/features/secret-shop/types/offers/transform';
import type { IOffersSections } from '~/features/secret-shop/types/offers/client.types';

export default class SecretShopRepository {
  static async getOffers(): Promise<IOffersSections> {
    const response = await $authApi.get<TOffersApiResponse>('/v1/offers');
    if (!response.status) throw new Error('No offers');

    return transform(response.data);
  }
}
