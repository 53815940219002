import type { IOfferApi, IOfferLabelApi, IOfferPriceApi, IOffersApiData } from './api.types';
import type {
  IOffer,
  IOfferLabel,
  IOfferPrice,
  IOfferRestraints,
  IOfferReward,
  IOffersSections,
  TOfferSectionsKey,
} from './client.types';
import { Colors } from '~/constants/colors.constants';

export const transform = (data: IOffersApiData): IOffersSections => {
  const result: IOffersSections = {
    personal: new Map(),
    event: new Map(),
    limited: new Map(),
    classic: new Map(),
  };

  let key: TOfferSectionsKey;
  for (key in result) {
    const parsedOffers = data[key].map((item): [number, IOffer] => {
      return [
        item.id,
        {
          id: item.id,
          background: item.background,
          price: getPrice(item.price),
          name: item.name,
          label: getLabel(item.label),
          restraints: getRestraints(item),
          reward: getReward(item),
        },
      ];
    });

    result[key] = new Map(parsedOffers);
  }

  return result;
};

const getPrice = ({ price, oldPrice }: IOfferPriceApi): IOfferPrice => ({
  new: price,
  old: oldPrice,
});

const getLabel = (label?: IOfferLabelApi): IOfferLabel | undefined => {
  if (!label || !label.color || !label.title) return;

  return {
    background: label.color[0] === '#' ? label.color : `#${label.color}`,
    color: Colors.NEUTRAL.WHITE,
    text: label.title,
  };
};

const getRestraints = (item: IOfferApi): IOfferRestraints => {
  const { offersCount, availableOffers, createdAt, expiredAt } = item;

  const counter = {
    max: offersCount,
    current: availableOffers,
  };

  const time =
    createdAt && expiredAt
      ? {
          from: new Date(createdAt).getTime() / 1000,
          till: new Date(expiredAt).getTime() / 1000,
        }
      : undefined;

  return {
    counter,
    time,
  };
};

const getReward = (item: IOfferApi): IOfferReward => {
  const { tastyCoins, bonusMoney, reward } = item;

  return {
    coins: tastyCoins,
    money: bonusMoney,
    cases: reward?.map((caseData) => {
      const { img, name, price, freeSpin, slug } = caseData;
      return {
        amount: freeSpin,
        name,
        oldPrice: price.price,
        image: img,
        slug,
      };
    }),
  };
};
