import SecretShopRepository from '~/features/secret-shop/api/repository';
import type { IOffer, IOffersSections } from '~/features/secret-shop/types/offers/client.types';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useAlertStore } from '~/store/alert/alert.store';

export const useOffersStore = defineStore('offers/list', () => {
  const alertStore = useAlertStore();

  const offers = ref<IOffersSections>({
    personal: new Map(),
    event: new Map(),
    limited: new Map(),
    classic: new Map(),
  });

  const selectedOffer = ref<IOffer | null>(null);

  const isLoaded = ref(false);

  const getOffers = async () => {
    try {
      offers.value = await SecretShopRepository.getOffers();
    } catch {
      alertStore.showError({
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      isLoaded.value = true;
    }
  };

  const findOfferById = (id: number) => {
    for (const key in offers.value) {
      const map = offers.value[key as keyof typeof offers.value];
      const offer = map.get(id);

      if (offer) return offer;
    }

    return null;
  };

  return { offers, selectedOffer, isLoaded, getOffers, findOfferById };
});
